import HomeAbout from '../components/HomeAbout';
import HomeContact from '../components/HomeContact';
import HomeHero from '../components/HomeHero';
import HomeRoadmap from '../components/HomeRoadmap';
import HomeTeam from '../components/HomeTeam';
import { useAlgo } from '../hooks/useAlgo';

function Home() {
  const { address } = useAlgo();
  return (
    <div>
      <HomeHero />
      <HomeAbout />
      <HomeRoadmap />
      <HomeTeam />
      <HomeContact />
    </div>
  );
}

export default Home;
