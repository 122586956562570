import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import config from '../config';
import Button from './Button';

function HomeHero() {
  return (
    <section className='relative'>
      <div className='absolute z-0 hidden left-12 top-48 xl:left-[9vw] xl:block'>
        <div className='w-[200px] flex-shrink-0'>
          <Parallax speed={10}>
            <img src='/cutouts/Daruma_A.png' alt='AlgoDaruma' className='object-contain w-full h-full' />
          </Parallax>
        </div>
      </div>

      <div className='absolute z-0 hidden top-56 left-24 xl:left-[10vw] xl:block'>
        <div className='w-[250px] flex-shrink-0'>
          <Parallax speed={15}>
            <img src='/cutouts/Red_Daruma.png' alt='AlgoDaruma' className='object-contain w-full h-full' />
          </Parallax>
        </div>
      </div>

      <div className='max-w-screen-xl px-4 py-32 mx-auto lg::py-56 lg:items-center lg:flex'>
        <div className='max-w-3xl mx-auto text-center'>
          <h1 className='pb-4 text-4xl font-extrabold text-transparent sm:text-6xl bg-clip-text bg-gradient-to-r from-theme-1 to-theme-2'>
            Welcome to AlgoDaruma!
          </h1>

          <p className='max-w-2xl mx-auto text-gray-300 sm:leading-relaxed sm:text-xl'>
            We have created AlgoDaruma to spark a community in the MetaVerse in hopes of achieving our yearly goals together. AlgoDaruma is our
            expression of art and our joy and we <strong>wish</strong> for you to join our journey!
          </p>

          <div className='flex flex-wrap justify-center gap-4 mt-8'>
            <Button href={config.nftExplorerSellers}>View Secondary</Button>
            <Button link href='/collection' theme='outline'>
              View Collection
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeHero;
