import React from 'react';
import Container from './Container';

function HomeAbout() {
  return (
    <Container className={'max-w-5xl'}>
      <div className='lg:py-16 md:py-12 py-9'>
        <div className='flex flex-col gap-10 lg:gap-32 md:flex-row'>
          <div className='flex-1 w-full'>
            <h2 className='w-full text-3xl font-bold leading-9 lg:text-4xl lg:leading-10'>
              What do you get when you combine the love of Algorand technology and Japanese culture?
            </h2>
            <p className='mt-6 text-base font-normal leading-6 text-gray-300 md:text-lg sm:leading-relaxed'>
              You get <strong>ALGODARUMA!</strong> The iconic Japanese idol that wishes are made yearly in the hopes of achieving them at the end of
              the year. If that wish is not fulfilled, the doll is then burned! Don’t fret, your digital AlgoDaruma’s wont be burned.
            </p>
          </div>

          <div className='w-[250px] flex-shrink-0 flex justify-center mx-auto'>
            <img src='/animated-logo.png' alt='AlgoDaruma' className='object-contain w-full h-full lg:scale-[2]' />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default HomeAbout;
