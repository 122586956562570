/* eslint-disable react/no-unescaped-entities */
/* This example requires Tailwind CSS v2.0+ */
import { MailIcon } from '@heroicons/react/solid';
import config from '../config';
import Button from './Button';
import Container from './Container';

export default function HomeContact() {
  return (
    <Container className='w-full max-w-6xl pt-12 md:pt-36'>
      <div className='relative overflow-hidden bg-gray-800 rounded-md'>
        <div className='h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2'>
          <img className='object-cover w-full h-full' src='/bg-1.png' alt='AlgoDaruma' />
        </div>
        <div className='relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16'>
          <div className='md:ml-auto md:w-1/2 md:pl-10'>
            <h2 className='text-base font-semibold tracking-wider text-gray-300 uppercase'>Want to get in touch with us?</h2>
            <p className='mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl'>We're here to help!</p>
            <p className='mt-3 text-lg text-gray-300'>
              Click on the “Contact Us” button so we can chat! We would love to hear suggestions and comments from you.
            </p>
            <div className='mt-8'>
              <Button href={`mailto:${config.contactEmail}`}>
                Contact us
                <MailIcon className='w-5 h-5 ml-3 -mr-1 text-gray-200' aria-hidden='true' />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
