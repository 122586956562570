import Image from 'next/image';
import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import { CheckIcon } from '@heroicons/react/solid';
import Container from './Container';
import config from '../config';
import clsx from 'clsx';

function HomeRoadmap() {
  return (
    <div className='relative'>
      <div className='absolute z-0 hidden top-5 left-12 lg:block'>
        <div className='w-[250px] flex-shrink-0'>
          <Parallax speed={10}>
            <img src='/cutouts/Daruma_B.png' alt='AlgoDaruma' className='object-contain w-full h-full' />
          </Parallax>
        </div>
      </div>

      <div className='absolute z-0 hidden top-36 left-36 lg:block'>
        <div className='w-[250px] flex-shrink-0'>
          <Parallax speed={15}>
            <img src='/cutouts/Female_Daruma_B.png' alt='AlgoDaruma' className='object-contain w-full h-full' />
          </Parallax>
        </div>
      </div>

      <div className='absolute z-0 hidden bottom-5 right-12 lg:block'>
        <div className='w-[250px] flex-shrink-0'>
          <Parallax speed={10}>
            <img src='/cutouts/Daruma_C.png' alt='AlgoDaruma' className='object-contain w-full h-full' />
          </Parallax>
        </div>
      </div>

      <div className='absolute z-0 hidden -bottom-12 right-56 lg:block'>
        <div className='w-[175px] flex-shrink-0'>
          <Parallax speed={15}>
            <img src='/cutouts/Daruma_E.png' alt='AlgoDaruma' className='object-contain w-full h-full' />
          </Parallax>
        </div>
      </div>

      <Container className='py-24 space-y-16 lg:pt-48 lg:pb-48'>
        <div className='relative max-w-2xl mx-auto text-center'>
          {/* <h2 className='mb-6 text-3xl font-extrabold tracking-tight sm:text-4xl'>The Road Ahead</h2>
           */}

          <div className='relative max-w-lg mx-auto'>
            <Image src='/roadmap-banner.png' alt='Roadmap' width={2047} height={1001} />
          </div>
        </div>

        <div>
          <dl className='max-w-6xl mx-auto mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8'>
            {config.roadmap.q1.map((it, index) => (
              <div key={index} className='relative'>
                <dt>
                  <CheckIcon className={clsx('absolute w-6 h-6', it.done ? 'text-green-500' : 'text-gray-500')} aria-hidden='true' />
                  <p className={clsx('text-lg font-medium leading-6  ml-9', it.done ? 'text-gray-300' : 'text-gray-500')}>{it.label}</p>
                </dt>
              </div>
            ))}
          </dl>
        </div>

        {/* <div>
          <div className='relative max-w-2xl mx-auto text-center'>
            <h2 className='text-3xl font-extrabold text-gray-100'>2nd Quarter</h2>
          </div>

          <dl className='max-w-6xl mx-auto mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8'>
            {config.roadmap.q2.map((it, index) => (
              <div key={index} className='relative'>
                <dt>
                  <CheckIcon className={clsx('absolute w-6 h-6', it.done ? 'text-green-500' : 'text-gray-600')} aria-hidden='true' />
                  <p className={clsx('text-lg font-medium leading-6  ml-9', it.done ? 'text-gray-300' : 'text-gray-500')}>{it.label}</p>
                </dt>
              </div>
            ))}
          </dl>
        </div> */}

        <div className='relative max-w-2xl mx-auto text-center'>
          <p className='mt-4 text-lg text-gray-500'>
            Our shuffle release will be dictated upon how fast each shuffle and auction sales are. We will ensure all of our followers are informed
            when these drops will happen. Furthermore, each quarter is sliced up in three month increments.
          </p>
        </div>
      </Container>
    </div>
  );
}

export default HomeRoadmap;
